body {
  background-size: 40px 40px;
  background-image: radial-gradient(circle, #000000 1px, rgba(255, 0, 0, 0) 1px);
}

svg {
  height: 2rem;
  width: 2rem;
  color: black;
}
